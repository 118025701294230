import React from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Pagination from "./Pagination";
import DateString from "../Components/DateString";
import "./DetailNews.css";
import "./Publikasi.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import Modal from "react-modal";
import { width } from "@mui/system";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Publikasi() {
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const params = useParams();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const Data = [
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
    {
      tanggal: "15 Februari 2022",
      deskripsi:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
    },
  ];
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(5);
  const [datapublikasi, setDataPublikasi] = React.useState([]);
  const indexOfLastIndex = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastIndex - postsPerPage;
  const currentPost = Data.slice(indexOfFirstPost, indexOfLastIndex);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [urldown, seturldown] = React.useState("");
  const [isemail, setemail] = React.useState("");

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getPublikasi = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 48);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        setDataPublikasi(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const openModal = (url) => {
    seturldown(url);
    setemail("");
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = "#f00";
  }
  function handlech(e) {
    var rs = e.target.value;
    var atps = rs.indexOf("@");
    var dots = rs.lastIndexOf(".");
    if (atps < 1 || dots < atps + 2 || dots + 2 >= rs.length) {
      document.getElementById("emailfrm").style.borderColor = "red";
      document.getElementById("wordemail").style.display = "block";
      return false;
    } else {
      document.getElementById("wordemail").style.display = "none";
      setemail(e.target.value);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }
  function opennewtab() {
    if (isemail) {
      setIsOpen(false);
      var bodyFormData = new FormData();
      bodyFormData.append("email", isemail);
      axios
        .post("/ajaxs/downsub", bodyFormData)
        .then(function (response) {
          // setDataPublikasi(response.data.data);
          window.open(urldown, "_blank");
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      document.getElementById("wordemail").style.display = "block";
      document.getElementById("emailfrm").style.borderColor = "red";
      return false;
    }
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPublikasi();
  }, []);
  const imageContainer = require("../Libs/Image/newspaper-article.png");
  const download = require("../Libs/Icon/document-download.png");

  return (
    <div style={{ paddingTop: 90, paddingBottom: 64 }}>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Download</h2>

        <span
          id="wordemail"
          style={{ display: "none", color: "red", fontSize: "10px" }}
        >
          Periksa email kamu kembali.
        </span>
        <input
          id="emailfrm"
          name="email"
          placeholder="Masukan Email Kamu"
          onChange={(e) => {
            handlech(e);
          }}
        />
        <br />
        <button
          variant="primary"
          className="button-news-all"
          onClick={closeModal}
        >
          close
        </button>
        <button
          variant="primary"
          className="button-news-all"
          onClick={opennewtab}
        >
          Download
        </button>
      </Modal>
      <img alt="" src={imageContainer} className="img-banner" />
      <div className="container">
        <Row className="heading-title">
          <div>
            <Typography variant="h5">Publication</Typography>
          </div>
          <Row>
            <Col>
              <div className="path-text-row">
                <Link to={"/"} className="text-disable">
                  Home/
                </Link>
                <Link to={"/publikasi"}>
                  <text className="text-active">Publication</text>
                </Link>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
      <div className="container" style={{ marginTop: 34 }}>
        {datapublikasi.map((item, index) => (
          <Row className="list-publish">
            <Col xs md={2}>
              <img
                src={"https://api.idsdb.id/upload/" + item.logoimg}
                style={{ width: "100%", height: "150px" }}
              ></img>
            </Col>
            <Col xs md={8}>
              <div>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="date-publish"
                >
                  {DateString(item.ins_date.split(" ")[0])}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  className="title-pubslih"
                >
                  {item.title}
                </Typography>
              </div>
            </Col>
            <Col xs md={2} className="download">
              <a
                //href={"https://api.idsdb.id/upload/" + item.img}
                href="#"
                onClick={(e) => {
                  openModal("https://api.idsdb.id/upload/" + item.img);
                }}
              >
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="text-download"
                >
                  Download
                  <img alt="" src={download} className="img-download" />
                </Typography>
              </a>
            </Col>
          </Row>
        ))}
        {/* <div className="pagination-row">
          <Pagination
            postPerPage={postsPerPage}
            totalPosts={Data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Publikasi;
