import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Breadcrumb } from "react-bootstrap";
import Pagination from "./Pagination";
import "./News.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";

function News() {
  const navigate = useNavigate();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const newsthumb2 = require("../Libs/Image/Rectangle-20.png");
  const imageNewsContainer = require("../Libs/Image/reading-news.png");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(12);
  const [datanews, setDataNews] = React.useState([]);
  const [baseurlimage, setBaseUrlImage] = React.useState("");
  const indexOfLastIndex = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastIndex - postsPerPage;
  // const currentPost = Data.slice(indexOfFirstPost, indexOfLastIndex);
  const params = new URLSearchParams(window.location.search);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getNews();
  }, []);
  const getNews = () => {
    var bodyFormData = new FormData();
    if (params.get("cat") == "siaran-pers") {
      bodyFormData.append("idcategory", 57);
    } else if (params.get("cat") == "news") {
      bodyFormData.append("idcategory", 49);
    } else {
      bodyFormData.append("idcategory", 58);
    }

    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data));
        if (response.data.status == 1) {
          setDataNews(response.data.data);
          setBaseUrlImage(response.data.baseurlimg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <Container fluid className="container-news">
        <Row>
          <Col md={12}>
            <img alt="" src={imageNewsContainer} className="img-banner" />
          </Col>
        </Row>
      </Container>
      <div className="news-grid-conatiner">
        <text className="news-title">
          {params.get("cat") === "siaran-pers" ? "Siaran Pers" : ""}
          {params.get("cat") === "news" ? "News" : ""}
          {params.get("cat") === "agenda" ? "Agenda" : ""}
        </text>
        <Row>
          <Col>
            <div className="path-text-row">
              <Breadcrumb>
                <Breadcrumb.Item href="/" className="text-disable">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="text-active" active>
                  {params.get("cat") === "siaran-pers" ? "Siaran Pers" : ""}
                  {params.get("cat") === "news" ? "News" : ""}
                  {params.get("cat") === "agenda" ? "Agenda" : ""}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
        <Row xs={1} md={3} style={{ marginTop: 26 }}>
          {datanews.map((i, index) => (
            <Col className="col-news-grid">
              <Card
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/detailnews/" + i.title, {
                    state: {
                      contents: i.contents,
                      image: baseurlimage + i.logoimg,
                      date: i.ins_date.split(" ")[0],
                    },
                  })
                }
              >
                <Card.Img
                  variant="top"
                  src={baseurlimage + i.logoimg}
                  className="img-thumbnail-grid"
                />
                <Card.Body>
                  <MuiThemeProvider theme={theme}>
                    <Card.Text>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="body1"
                        className="text-title-grid"
                        gutterBottom
                      >
                        {i.title}
                      </Typography>
                    </Card.Text>
                  </MuiThemeProvider>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <div className="pagination-row">
          <Pagination
            postPerPage={postsPerPage}
            totalPosts={Data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div> */}
      </div>
    </>
  );
}

export default News;
