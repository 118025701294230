import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DateString from "../Components/DateString";
import "./DetailNews.css";
import "./News.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";

function DetailNews() {
  // let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-detail" style={{ paddingTop: 120 }}>
      <div className="container">
        <MuiThemeProvider theme={theme}>
          <Typography variant="h4" gutterBottom align="center">
            {params.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom align="center">
            BY Admin, FIRST PUBLISHED IN TODAY,{" "}
            {DateString(location.state.date)}
          </Typography>
        </MuiThemeProvider>
        <Row xs md={12}>
          <Col className="centerd-img">
            {" "}
            <img alt="" src={location.state.image} className="img-banner" />
          </Col>
        </Row>
        <Row>
          <div className="body-news">
            <MuiThemeProvider theme={theme}>
              <Typography variant="body1" align="left" paragraph>
                <div
                  dangerouslySetInnerHTML={{
                    __html: location.state.contents,
                  }}
                />
              </Typography>
            </MuiThemeProvider>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default DetailNews;
