import React from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import HoverVideoPlayer from "react-hover-video-player";
import DateString from "../Components/DateString";
import "./Video.css";
import { Button } from "bootstrap";
import Modal from "react-modal";
const customStyles = {
  content: {
    width: "55%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function Video() {
  // let navigate = useNavigate();
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const params = useParams();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const [datavideo, setDataVideo] = React.useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [imgzoom, setimgzoom] = React.useState("");
  const [titleimg, settitleimg] = React.useState("");
  const openModal = (url, title) => {
    setimgzoom(url);
    settitleimg(title);
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const getVideo = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 5);
    axios
      .post("/ajaxs/video", bodyFormData)
      .then(function (response) {
        setDataVideo(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getVideo();
  }, []);
  return (
    <div className="container" style={{ paddingTop: 90, paddingBottom: 148 }}>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>{titleimg}</h2>

        <ReactPlayer
          url={imgzoom}
          width="100%"
          controls
          playing
          // muted={false}
          // playIcon={<button>Play</button>}
          // light="https://i.stack.imgur.com/zw9Iz.png"
        />
      </Modal>
      <Container fluid>
        <Row className="heading-title">
          <div>
            <Typography variant="h5">Video</Typography>
          </div>
          <Row>
            <Col>
              <div className="path-text-row">
                <Link to={"/"} className="text-disable">
                  Home/
                </Link>
                <Link to={"/video"}>
                  <text className="text-active">Video</text>
                </Link>
              </div>
            </Col>
          </Row>
        </Row>
        <Row xs={8} md={8} lg={8}>
          <Col>
            <ReactPlayer
              url={datavideo.length !== 0 ? datavideo[0].url : ""}
              width="100%"
              // height="100%"
              controls
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Col md={11} xs>
            <Typography
              variant="Body2"
              align="left"
              className="title-video-container"
              gutterBottom
            >
              {datavideo.length !== 0 ? datavideo[0].title : ""}
            </Typography>
          </Col>
          <Col md={1} xs>
            <Typography
              gutterBottom
              align="left"
              className="date-text"
              variant="subtitle2"
            >
              {datavideo.length !== 0
                ? DateString(datavideo[0].ins_date.split(" ")[0])
                : ""}
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginTop: 14, marginBottom: 31 }}>
          <Col>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              className="sub-video-text"
              align="justify"
            >
              {datavideo.length !== 0
                ? datavideo[0].description !== ""
                  ? datavideo[0].description
                  : "Description Not Available"
                : ""}
            </Typography>
          </Col>
        </Row>

        <Row
          xs={2}
          md={3}
          lg={3}
          // className="g-4 justify-content-md-center"
          // style={{ margin: 12 }}
          className="g-4"
        >
          {datavideo.map((item, index) => (
            <Col md={3} className="hover-video">
              <div
                key={index}
                onClick={(e) => {
                  openModal(item.url, item.title);
                }}
              >
                {/* */}
                <img
                  src={"https://www.api.idsdb.id/upload/" + item.thumimage}
                  style={{ width: "100%" }}
                />

                <Typography
                  variant="h6"
                  className="text-video-grid"
                  align="justify"
                >
                  {item.title}
                </Typography>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Video;
