import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
// import { Button } from "../Button.js";
import { Navbar, Container, Nav, NavDropdown, Form } from "react-bootstrap";
import { style } from "@mui/system";
import "@fontsource/montserrat";
// style.fontWeigth
function NavbarComp() {
  const [click, setClick] = React.useState(false);
  const [form, setform] = React.useState(true);
  const logo = require("../../Libs/Icon/logo-index.png");
  const searchLogo = require("../../Libs/Icon/search-normal-navbar.png");
  const navigate = useNavigate();
  const [showa, setShowa] = React.useState(false);
  const [isberanda, setisberanda] = React.useState("");
  const [iscakupan, setiscakupan] = React.useState("");
  const [iskonsepisdb, setiskonsepisdb] = React.useState("");
  const [iskonsorsium, setiskonsorsium] = React.useState("");
  const [iskomite, setiskomite] = React.useState("");
  const [iskontak, setiskontak] = React.useState("");
  const [iskonsepindex, setiskonsepindex] = React.useState("");
  const [isindex, setisindex] = React.useState("");
  const [islap, setislap] = React.useState("");
  const [isberita, setisberita] = React.useState("");
  const [issiaran, setissiaran] = React.useState("");
  const [isinfografis, setisinfografis] = React.useState("");
  const [isfoto, setisfoto] = React.useState("");
  const [isvideo, setisvideo] = React.useState("");
  const [isisdb, setisisdb] = React.useState("");

  const showDropdowna = (e) => {
    setShowa(!showa);
  };
  const hideDropdowna = (e) => {
    setTimeout(() => {
      setShowa(false);
    }, 100);
  };
  const [showb, setShowb] = React.useState(false);
  const showDropdownb = (e) => {
    setShowb(!showb);
  };
  const hideDropdownb = (e) => {
    setTimeout(() => {
      setShowb(false);
    }, 100);
  };
  const [showc, setShowc] = React.useState(false);
  const showDropdownc = (e) => {
    setShowc(!showc);
  };
  const hideDropdownc = (e) => {
    setTimeout(() => {
      setShowc(false);
    }, 100);
  };
  const [showe, setShowe] = React.useState(false);
  const showDropdowne = (e) => {
    setShowe(!showe);
  };
  const hideDropdowne = (e) => {
    setTimeout(() => {
      setShowe(false);
    }, 100);
  };
  const [showd, setShowd] = React.useState(false);
  const showDropdownd = (e) => {
    setShowd(!showd);
  };
  const hideDropdownd = (e) => {
    setTimeout(() => {
      setShowd(false);
    }, 100);
  };
  let MenuView;

  const handleClickMenu = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => {
    setClick(false);
  };

  React.useEffect(() => {
    // if (window.location.pathname == "/") {
    //   setisberanda("selected");
    // }
    // if (window.location.pathname == "/index/data") {
    //   setisindex("selected");
    // }
    // if (window.location.pathname == "/index/data") {
    //   setisindex("selected");
    // }
    const showButton = () => {
      if (window.innerWidth <= 960) {
        setform(false);
      } else {
        setform(true);
      }
    };
    window.addEventListener("resize", showButton);
  }, [form]);

  if (click) {
    MenuView = (
      <Form className="d-flex" style={{ width: 300 }}>
        <Form.Control
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
        />
      </Form>
    );
  } else {
    MenuView = (
      <>
        <Nav.Link
          href="/"
          className={isberanda}
          style={{ fontWeight: "900" }}
          // onMouseOver={(e) => {
          //   setisberanda("selected");
          // }}
          onMouseLeave={(e) => {
            setisberanda("");
          }}
        >
          Beranda
        </Nav.Link>
        <NavDropdown
          title="Konsep"
          id="1"
          show={showc}
          // onMouseOver={(e) => {
          //   setisisdb("selected");
          // }}
          onMouseEnter={showDropdownc}
          onMouseLeave={(e) => {
            hideDropdownc();
          }}
          className={isisdb}
        >
          <NavDropdown.Item href="/pagecontent/?watch=3090&a=konsep">
            Latar Belakang
          </NavDropdown.Item>
          <NavDropdown.Item href="/pagecontent/?watch=3069&a=konsep">
            Cakupan dan Tujuan
          </NavDropdown.Item>
          <NavDropdown.Item href="/pagecontent/?watch=3070&a=konsep">
            Konsep IDSDB
          </NavDropdown.Item>
          <NavDropdown.Item href="/pagecontent/?watch=3080&a=konsep">
            Kerangka Pikir
          </NavDropdown.Item>
          {/*  */}
        </NavDropdown>
        <NavDropdown
          title="Index"
          id="2"
          show={showa}
          // onMouseOver={(e) => {
          //   setisisdb("selected");
          // }}
          onMouseEnter={showDropdowna}
          onMouseLeave={(e) => {
            hideDropdowna();
            //setisisdb("");
          }}
        >
          <NavDropdown.Item href="/pagecontent/?watch=3064&a=metodelogi">
            Metodologi
          </NavDropdown.Item>
          <NavDropdown.Item href="/index/data/?dat=nasional.svg">
            Index Data
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          title="Publikasi"
          id="3"
          show={showb}
          onMouseEnter={showDropdownb}
          onMouseLeave={hideDropdownb}
        >
          <NavDropdown.Item href="/publikasi">Laporan</NavDropdown.Item>
          <NavDropdown.Item href="/news/?cat=news">
            Berita Media
          </NavDropdown.Item>
          <NavDropdown.Item href="/news/?cat=siaran-pers">
            Siaran Pers
          </NavDropdown.Item>
          <NavDropdown.Item href="/gallery/?cat=infografis">
            Infografis
          </NavDropdown.Item>
        </NavDropdown>

        <NavDropdown
          title="Informasi"
          id="4"
          show={showd}
          onMouseEnter={showDropdownd}
          onMouseLeave={hideDropdownd}
        >
          <NavDropdown.Item href="/news/?cat=agenda">Agenda</NavDropdown.Item>
          <NavDropdown.Item href="/gallery/?cat=foto">Foto</NavDropdown.Item>
          <NavDropdown.Item href="/video">Video</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          title="Tentang kami"
          id="5"
          show={showe}
          onMouseEnter={showDropdowne}
          onMouseLeave={hideDropdowne}
        >
          <NavDropdown.Item href="/pagecontent/?watch=3071&a=tentangkami">
            Konsorsium pengusung
          </NavDropdown.Item>
          <NavDropdown.Item href="/pagecontent/?watch=3072&a=tentangkami">
            Komite Pengarah
          </NavDropdown.Item>
          <NavDropdown.Item href="/pagecontent/?watch=3066&a=tentangkami">
            Kontak kami
          </NavDropdown.Item>
        </NavDropdown>
      </>
    );
  }
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <img className="logo-header" alt="" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav activeKey={1} className="side-menu">
              {MenuView}
              <Nav.Link onClick={handleClickMenu}>
                {/* <img className="search-icon" alt="" src={searchLogo} /> */}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComp;
