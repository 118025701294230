import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Breadcrumb } from "react-bootstrap";
import Pagination from "./Pagination";
import "./Gallery.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    //marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Galery() {
  const navigate = useNavigate();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const newsthumb2 = require("../Libs/Image/Rectangle-20.png");
  const imageNewsContainer = require("../Libs/Image/reading-news.png");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(12);
  const [datanews, setDataNews] = React.useState([]);
  const [baseurlimage, setBaseUrlImage] = React.useState("");
  const [imgzoom, setimgzoom] = React.useState("");
  const [titleimg, settitleimg] = React.useState("");
  const indexOfLastIndex = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastIndex - postsPerPage;
  // const currentPost = Data.slice(indexOfFirstPost, indexOfLastIndex);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const params = new URLSearchParams(window.location.search);
  const openModal = (url, title) => {
    setimgzoom(url);
    settitleimg(title);
    setIsOpen(true);
  };
  Modal.defaultStyles.overlay.overflowY = "auto";
  Modal.defaultStyles.overlay.zIndex = 1111111111;
  Modal.defaultStyles.overlay.top = 0;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getNews();
  }, []);
  const getNews = () => {
    var bodyFormData = new FormData();
    if (params.get("cat") == "infografis") {
      bodyFormData.append("idcategory", 56);
    } else {
      bodyFormData.append("idcategory", 55);
    }

    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        if (response.data.status == "1") {
          console.log(JSON.stringify(response.data.data));
          setDataNews(response.data.data);
          setBaseUrlImage(response.data.baseurlimg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      {/* <Container fluid className="container-news">
        <Row>
          <Col md={12}>
            <img alt="" src={imageNewsContainer} className="img-banner" />
          </Col>
        </Row>
      </Container> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {params.get("cat") == "infografis" ? (
          <img src={imgzoom} style={{ width: "100%", height: "800px" }}></img>
        ) : (
          <img src={imgzoom} style={{ width: "100%" }}></img>
        )}
      </Modal>
      <div className="news-grid-conatiner">
        <text className="news-title">{params.get("cat")}</text>
        <Row>
          <Col>
            {/* <div className="path-text-row">
              <Breadcrumb>
                <Breadcrumb.Item href="/" className="text-disable">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className="text-active" active>
                  News
                </Breadcrumb.Item>
              </Breadcrumb>
            </div> */}
          </Col>
        </Row>
        <Row xs={1} md={3} style={{ marginTop: 26 }}>
          {datanews.map((i, index) => (
            <Col className="col-news-grid">
              <Card
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  openModal(baseurlimage + i.logoimg, i.title);
                }}
                // onClick={() =>
                //   navigate("/detailnews/" + i.title, {
                //     state: {
                //       contents: i.contents,
                //       image: baseurlimage + i.logoimg,
                //       date: i.ins_date.split(" ")[0],
                //     },
                //   })
                // }
              >
                {params.get("cat") == "infografis" ? (
                  <Card.Img
                    variant="top"
                    src={baseurlimage + i.logoimg}
                    className="img-thumbnail-grid-ah"
                  />
                ) : (
                  <Card.Img
                    variant="top"
                    src={baseurlimage + i.logoimg}
                    className="img-thumbnail-grid"
                  />
                )}

                <Card.Body>
                  <MuiThemeProvider theme={theme}>
                    <Card.Text>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="body1"
                        className="text-title-grid"
                        gutterBottom
                      >
                        {i.title}
                      </Typography>
                    </Card.Text>
                  </MuiThemeProvider>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <div className="pagination-row">
          <Pagination
            postPerPage={postsPerPage}
            totalPosts={Data.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div> */}
      </div>
    </>
  );
}

export default Galery;
