import React from "react";
import "./Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import Background from "../../Libs/Image/element-1.png";
function Footer() {
  const indexLogo = require("../../Libs/Icon/image-27.png");
  const kinaraLogo = require("../../Libs/Icon/Logo-Kinara-Indonesia.png");
  const kppoadLogo = require("../../Libs/Icon/KPPOD-Logo.png");
  const terraLogo = require("../../Libs/Icon/terra-03.png");
  const logo = require("../../Libs/Icon/logo-index.png");
  return (
    <>
      <Container fluid className="container-footer">
        <Row style={{ backgroundColor: "#1C79BE" }}>
          {/* <Col></Col>
          <Col className="col-index-logo">
            <img className="img-index-logo" alt="" src={indexLogo} />
          </Col>
          <Col></Col> */}
          <Col md={2} style={{ margin: "10px", align: "center" }}>
            <img
              style={{
                backgroundColor: "#ffff",
                width: "200px",
                alignSelf: "center",
              }}
              src={logo}
            ></img>
          </Col>
          <Col md={3} style={{ margin: "10px", color: "#fff" }}>
            Permata Kuningan Lt. 10
            <br />
            Jl. Kuningan Mulia Kav. 9C
            <br />
            Guntur, Setiabudi
            <br />
            Jakarta Selatan, 12980
            <br />
            (021) 8378 0642
          </Col>
          <Col
            md={2}
            style={{
              margin: "10px",
            }}
          >
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div>Beranda</div>
            </a>

            <a
              href="/video"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div style={{ marginTop: "8px" }}>Video</div>
            </a>

            <a
              href="/news"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              <div style={{ marginTop: "8px" }}>Berita Media</div>
            </a>
          </Col>
          <Col
            md={2}
            style={{
              margin: "10px",
            }}
          >
            <a
              href="/index/data/?dat=nasional.svg"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div>Index Data</div>
            </a>

            <a
              href="/gallery/?cat=infografis"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div style={{ marginTop: "8px" }}>Infografis</div>
            </a>

            <a
              href="/gallery/?cat=foto"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              <div style={{ marginTop: "8px" }}>Foto</div>
            </a>
          </Col>
          <Col
            md={2}
            style={{
              margin: "10px",
            }}
          >
            <a
              href="/publikasi"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div>Laporan</div>
            </a>

            <a
              href="/pagecontent/?watch=3069&a=about-us"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "16px",
              }}
            >
              <div style={{ marginTop: "8px" }}>About us</div>
            </a>

            <a
              href="/detailcontent/?watch=3066&a=contact"
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bolder",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              <div style={{ marginTop: "8px" }}>Kontak kami</div>
            </a>
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          style={{
            backgroundColor: "#B3D258",
          }}
        >
          <center>
            <Col
              style={{
                backgroundColor: "#B3D258",
                margin: "18px",
                fontSize: "15px",
              }}
            >
              <b>
                Hak Cipta © 2022 Konsorsium Indeks Daya Saing Daerah
                Berkelanjutan. Semua Hak Dilindungi.
              </b>
            </Col>
          </center>
          {/* <Col
            className="col-index-logo1"
            md={2}
            style={{
              // backgroundColor: "red",
              display: "flex",
              // alignItems: "flex-end",
            }}
          >
            <img
              style={{ width: 100 }}
              className="kppod-logo"
              alt=""
              src={kppoadLogo}
            />
          </Col>
          <Col
            className="col-index-logo1"
            md={2}
            style={{
              display: "flex",
              // alignItems: "flex-end",
            }}
          >
            <img
              style={{ width: 100 }}
              className="kppod-logo"
              alt=""
              src={terraLogo}
            />
          </Col>
          <Col
            className="col-index-logo1"
            md={2}
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <img
              style={{ width: 100 }}
              className="kppod-logo"
              alt=""
              src={kinaraLogo}
            />
          </Col> */}
        </Row>
        {/* <Row className="justify-content-md-center g-4">
          <Col className="col-index-logo" md={1}>
            <text className="text-footer">Index Data</text>
          </Col>
          <Col className="col-index-logo" md={1}>
            <text className="text-footer">Publikasi</text>
          </Col>
          <Col className="col-index-logo" md={1}>
            <text className="text-footer">News</text>
          </Col>
          <Col className="col-index-logo" md={1}>
            <text className="text-footer">Video</text>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default Footer;
