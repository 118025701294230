import * as React from "react";
import Select from "react-select";
import Table from "react-bootstrap/Table";
// import { SVGMap } from "react-svg-map";
// import Indonesia from "@svg-maps/indonesia";
import { ReactSVG } from "react-svg";
import "react-svg-map/lib/index.css";
import { Container, Row, Col } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import "./DetailIndex.css";

export default function DetailIndex() {
  const petaIcon = require("../Libs/Icon/Peta.png");
  const cloudI = require("../Libs/Icon/cloud-notif-i.png");
  const frameI = require("../Libs/Icon/frame-i.png");
  const buildingsI = require("../Libs/Icon/buildings-i.png");
  const statusupI = require("../Libs/Icon/status-up-i.png");
  const axios = require("axios").default;

  axios.defaults.baseURL = "https://api.idsdb.id";
  const [dataranking, setDataRanking] = React.useState([]);
  const [showmap, setShowmap] = React.useState(true);
  const [radardat, setradardat] = React.useState([]);
  const [radarlabel, setradarlabel] = React.useState("");
  const [nameprop, setnameprop] = React.useState("");
  const [namepropfix, setnamepropfix] = React.useState("");
  const [kabnamefix, setkabnamefix] = React.useState("");
  const [avgnas, setavgnas] = React.useState(0);
  const [selkab, setselkab] = React.useState([]);
  const [svgmaps, setsvgmaps] = React.useState([]);
  const [sumdatkab, setsumdatkab] = React.useState([]);
  const [valprovince, setvalprovince] = React.useState("");
  const params = new URLSearchParams(window.location.search);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const [selyear, setselyear] = React.useState([]);

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  ChartJS.defaults.color = "#000";
  var stylemap = {};
  var stylemapdet = {};
  var wdconcard = {};
  var styletblrangking = {};
  var styleprogres = {};
  wdconcard.width = "100%";
  stylemapdet.display = "none";
  if (!showmap) {
    stylemap.display = "none";
    stylemapdet.display = "block";
  }
  if (showmap) {
    // alert("test");
    stylemap.display = "block";
    stylemapdet.display = "none";
  }
  const getRanking = () => {
    var bodyFormData = new FormData();

    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    if (params.get("tahun")) {
      bodyFormData.append("tahun", params.get("tahun"));
    }
    bodyFormData.append("limit", 400);
    axios
      .post("/ajaxs/rangking", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        setDataRanking(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRankingbyprov = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);
    bodyFormData.append("province", params.get("prov"));
    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    axios
      .post("/ajaxs/rangking", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        //setShowmap(false);
        setavgnas(response.data.avgnas);
        setDataRanking(response.data.data);
        setsvgmaps(response.data.data);
        setnameprop(response.data.nameprop);
        setnamepropfix(response.data.nameprop);
        styletblrangking.display = "block";
        const detailgrafik = document.getElementById("detailgrafik");
        detailgrafik.style.display = "none";
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getRankingsearch = (nameprov) => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);
    bodyFormData.append("keyword", nameprov);
    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    axios
      .post("/ajaxs/rangking", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        setShowmap(true);
        setDataRanking(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getDetkab = (nameprov) => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);
    bodyFormData.append("namekab", nameprov);
    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    axios
      .post("/ajaxs/detkabsum", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        //setShowmap(true);
        setsumdatkab(response.data.data);

        //setselkab(response.data.data[0].kab)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getOneKab = (nameprov) => {
    getDetkab(nameprov);
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);
    bodyFormData.append("namekab", nameprov);
    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    axios
      .post("/ajaxs/detkab", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        //setShowmap(true);
        setradardat(response.data.data);
        setradarlabel(response.data.kabname);
        setsvgmaps(response.data.datadat);
        setavgnas(response.data.avgnas);
        setnameprop(response.data.kabname);
        setkabnamefix("/" + response.data.kabname);
        //setselkab(response.data.data[0].kab)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getProvince = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);
    if (selyear.value) {
      bodyFormData.append("tahun", selyear.value);
    }
    if (params.get("tahun")) {
      bodyFormData.append("tahun", params.get("tahun"));
    }
    //bodyFormData.append("keyword", );
    axios
      .post("/ajaxs/province", bodyFormData)
      .then(function (response) {
        const detailgrafik = document.getElementById("detailgrafik");
        detailgrafik.style.display = "none";
        //console.log(JSON.stringify(response.data.avgnas));
        setavgnas(response.data.avgnas);
        setsvgmaps(response.data.data);
        //#c2bcbc
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleChange = (selectedOption) => {
    setselkab(selectedOption);
    //console.log(`Option selected:`, selectedOption);
  };
  const handleChangeyear = (selectedOption) => {
    setselyear(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };
  const clickCari = () => {
    //alert(selkab.value);
    if (selyear.value == "2022") {
      alert("Data 2022 Tidak tersedia");
    } else {
      if (selkab.value) {
        getOneKab(selkab.value);
        const mapid = document.getElementById("mapsid");
        mapid.style.display = "none";
        const radarid = document.getElementById("radarid");
        const infomap = document.getElementById("infomap");
        const detailgrafik = document.getElementById("detailgrafik");
        setTimeout(() => {
          radarid.style.display = "block";
          detailgrafik.style.display = "block";
          infomap.style.display = "none";
        }, 700);
      } else {
        window.location.replace(
          "/index/data/?dat=nasional.svg&tahun=" + selyear.value
        );
      }
    }
  };
  const handlekeyup = (val) => {
    //var xml = new XMLParser().parseFromString(val);
    //console.log(val);
    if (val.length > 3) {
      getRankingsearch(val);
    }
    if (val.length < 1) {
      getRanking();
    }
    //
  };
  const clickrangking = (isnas, dat, provname) => {
    if (isnas == 1) {
      window.location.replace("/index/data/?dat=" + dat + "&prov=" + provname);
    } else {
      if (dat) {
        getOneKab(provname);
        const mapid = document.getElementById("mapsid");
        mapid.style.display = "none";
        const detailgrafik = document.getElementById("detailgrafik");
        const radarid = document.getElementById("radarid");
        const infomap = document.getElementById("infomap");
        setTimeout(() => {
          radarid.style.display = "block";
          infomap.style.display = "none";
          detailgrafik.style.display = "block";
        }, 700);
      }
    }
  };
  //console.log("c", this.customIndonesia);
  React.useEffect(() => {
    //drilldown(Highcharts);

    window.scrollTo(0, 0);

    if (params.get("dat") == "nasional.svg") {
      if (params.get("tahun") == "1") {
        setselyear({ value: "1", label: "2020" });
      } else if (params.get("tahun") == "2") {
        setselyear({ value: "2", label: "2022 - 100%" });
      }
      console.log(selyear.value);
      getRanking();
      getProvince();
    } else {
      const detailgrafik = document.getElementById("detailgrafik");
      detailgrafik.style.display = "none";
      getRankingbyprov();
    }

    setInterval(() => {
      setIsDisplayed(true);
    }, 800);
    //console.log(svgmaps);
  }, []);

  const datacon = {
    labels: [
      "Lingkungan Lestari",
      "Ekonomi Berkelanjutan",
      "Inklusi Sosial",
      "Tata Kelola Berkelanjutan",
    ],
    datasets: [
      {
        label: radarlabel,
        data: radardat,
        backgroundColor: "rgba(156, 192, 241, 0.8)",
        borderColor: "rgba(8, 108, 249, 0.8)",
        borderWidth: 4,
        fill: true,
      },
    ],
  };
  const RadarOptions = {
    responsive: true,
    scales: {
      r: {
        backgroundColor: "rgba(38, 140, 241, 0.03)",
        min: 0,
        max: 100,
        beginAtZero: true,
        angleLines: {
          color: "#3a63a6", // lines radiating from the center
        },
        grid: {
          color: "#B3D257", // lines radiating from the center
        },
        pointLabels: {
          font: {
            size: 10,
            weight: "bolder",
          },

          circular: true,
        },
      },
    },
  };

  return (
    <div
      className="container-index"
      style={{ marginTop: 90, textAlign: "center" }}
    >
      <center>
        <div
          style={{ backgroundColor: "#d5e5f0", width: "80%", margin: "5px" }}
        >
          <h2 className="title-index">
            Indeks Daya Saing Daerah Berkelanjutan
          </h2>
          <p style={{ marginTop: "-16px" }}>
            Indeks DSDB dibentuk sebagai resultan dari kinerja pilar lingkungan
            lestari, ekonomi berkelanjutan, sosial inklusif, dan tata kelola
            berkelanjutan.{" "}
          </p>
        </div>
      </center>
      <br />
      <Container
        className="containerCard"
        style={(stylemap, { height: "60px" })}
      >
        <Row
          xs={0}
          md={1}
          lg={3}
          className="g-2"
          style={{ textAlign: "center" }}
        >
          <Col>
            <Select
              options={dataranking}
              value={selkab}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col>
            {/* { value: "1", label: "2020" }, */}
            <Select
              options={[
                { value: "3", label: "2022 - 80%" },
                { value: "2", label: "2022 - 100%" },
              ]}
              value={selyear}
              selectedValue={selyear}
              onChange={(e) => handleChangeyear(e)}
            />
          </Col>
          <Col>
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => clickCari()}
            >
              Cari
            </button>
          </Col>
        </Row>
        <br />
      </Container>
      <div className="container" style={{ padding: "10px" }}>
        <div style={{ textAlign: "left", textTransform: "capitalize" }}>
          <a href="/">Beranda</a>/
          <a href="/index/data/?dat=nasional.svg">Nasional</a>/
          <a
            href={
              "/index/data/?dat=" +
              params.get("dat") +
              "&prov=" +
              params.get("prov")
            }
          >
            {namepropfix}
          </a>
          {kabnamefix}
        </div>
        <div style={stylemap} id="mapsid">
          <center>
            <div>
              <ReactSVG
                afterInjection={(error, svg) => {
                  if (error) {
                    console.error(error);
                    return;
                  }
                }}
                beforeInjection={(svg) => {
                  svg.classList.add("svg-map");
                  const [firstGElement] = [...svg.querySelectorAll("g")];
                  // firstGElement
                  //   .querySelectorAll("path")
                  //   .setAttribute("class", "svg-map__location");

                  firstGElement.setAttribute("fill", "white");
                  const prov = firstGElement.querySelectorAll("path");
                  for (var i = 0; i < prov.length; i++) {
                    prov[i].setAttribute("nrt", "0");
                  }
                  // if (params.get("dat") == "nasional.svg") {
                  svgmaps.map((datamap, key) => {
                    //const els
                    var attrcus = "";
                    if (params.get("dat") == "nasional.svg") {
                      attrcus = "data-a1kpkname";
                    } else {
                      attrcus = "data-a2kpkname";
                    }
                    const [els] = svg.querySelectorAll(
                      "path[" +
                        attrcus +
                        '="' +
                        datamap.name.replace("KABUPATEN ", "") +
                        '"]'
                    );
                    if (els) {
                      if (datamap.nrt < 30) {
                        //console.log(els);
                        els.setAttribute("fill", "#0000ff");
                        els.setAttribute("class", "svg-map__location");
                      } else if (datamap.nrt > 30 && datamap.nrt < 60) {
                        //console.log("elments", els);
                        els.setAttribute("fill", "#1175BC");
                        els.setAttribute("class", "svg-map__location");
                      } else if (datamap.nrt > 60 && datamap.nrt < 80) {
                        els.setAttribute("fill", "#B3D257");
                        els.setAttribute("class", "svg-map__location");
                      } else if (datamap.nrt > 80 && datamap.nrt < 100) {
                        els.setAttribute("fill", "#2e9449");
                        els.setAttribute("class", "svg-map__location");
                      } else {
                        if (els) {
                          els.setAttribute("class", "svg-map__location");
                        }
                      }
                    }

                    //console.log(els);
                    if (params.get("dat") == "nasional.svg") {
                      const [jkt] = svg.querySelectorAll(
                        'path[data-a1kpkname="DKI JAKARTA"]'
                      );
                      jkt.setAttribute("fill", "#c2bcbc");
                      jkt.setAttribute("class", "svg-map__location");
                    } else {
                      svg.setAttribute("style", "width:30% !important");
                    }
                    if (els) {
                      els.setAttribute("nrt", datamap.nrt);
                      els.setAttribute("petasvg", datamap.code);
                    }
                  });
                }}
                loading={() => <span>Loading</span>}
                onClick={(e) => {
                  // console.log(e.target.getAttribute("petasvg"));
                  //setpeta(e.target.getAttribute("petasvg"));
                  if (params.get("dat") == "nasional.svg") {
                    if (e.target.getAttribute("petasvg")) {
                      window.location.replace(
                        "/index/data/?dat=" +
                          e.target.getAttribute("petasvg") +
                          "&prov=" +
                          e.target.getAttribute("data-a1kpkname")
                      );
                    }
                  } else {
                    if (e.target.getAttribute("data-a2kpkname")) {
                      getOneKab(
                        "KABUPATEN " + e.target.getAttribute("data-a2kpkname")
                      );
                      var mapid = document.getElementById("mapsid");
                      mapid.style.display = "none";
                      const detailgrafik =
                        document.getElementById("detailgrafik");
                      var radarid = document.getElementById("radarid");
                      const infomap = document.getElementById("infomap");
                      setTimeout(() => {
                        radarid.style.display = "block";
                        infomap.style.display = "none";
                        detailgrafik.style.display = "block";
                      }, 700);
                    }
                  }
                }}
                onMouseOver={(e) => {
                  // console.log(e.target);
                  var tooltipSpan = document.getElementById("tooltip-span");

                  //console.log(tooltipSpan);
                  var x = e.clientX,
                    y = e.clientY;
                  tooltipSpan.style.top = y + 15 + "px";
                  tooltipSpan.style.left = x + 15 + "px";
                  //tooltipSpan.style.display = "block";
                  tooltipSpan.style.position = "fixed";
                  tooltipSpan.style.overflow = "hidden";
                  //console.log(e.target.getAttribute("data-a1kpkname"));
                  if (params.get("dat") == "nasional.svg") {
                    if (e.target.getAttribute("data-a1kpkname")) {
                      tooltipSpan.style.display = "block";
                      setvalprovince(
                        e.target.getAttribute("data-a1kpkname") +
                          " - " +
                          e.target.getAttribute("nrt")
                      );
                    }
                  } else {
                    if (e.target.getAttribute("data-a2kpkname")) {
                      tooltipSpan.style.display = "block";
                      setvalprovince(
                        e.target.getAttribute("data-a2kpkname") +
                          " - " +
                          e.target.getAttribute("nrt")
                      );
                    }
                  }
                }}
                onMouseOut={(e) => {
                  var tooltipSpan = document.getElementById("tooltip-span");
                  tooltipSpan.style.display = "none";
                  // tooltipSpan.style.;
                }}
                src={"https://www.api.idsdb.id/upload/svg/" + params.get("dat")}
              />
              <div
                id="tooltip-span"
                style={{
                  display: "none",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                {valprovince}
              </div>
            </div>

            {/* <div className="examples__block__map__tooltip" style={tooltipStyle}>
              {pointedLocation}
            </div> */}
          </center>
        </div>
        <div style={stylemapdet} id="radarid">
          <center>
            <br />
            <Col
              md={5}
              style={{
                backgroundColor: "rgb(179, 208, 235)",
                borderRadius: "25px",
              }}
            >
              <Radar data={datacon} options={RadarOptions} />
            </Col>
          </center>
        </div>

        <br />

        <Container className="containerCard" style={stylemap}>
          <br />
          <Col style={{ marginTop: "10px" }} id="detailgrafik">
            {isDisplayed && (
              <>
                <Row
                  md={2}
                  className="g-2"
                  style={{ justifyContent: "center" }}
                >
                  <Col md={2}>
                    <div
                      className="cards "
                      style={{ justifyContent: "center", display: "block" }}
                    >
                      <div className="title-card" style={{ color: "#1175BC" }}>
                        Rangking
                      </div>
                      <div className="roundeddiv">{svgmaps[0].rangking}</div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      className="cards"
                      style={{ justifyContent: "center", display: "block" }}
                    >
                      <div className="title-card" style={{ color: "#1175BC" }}>
                        Nilai Index
                      </div>
                      <div className="roundeddiv">{svgmaps[0].idsdb}</div>
                    </div>
                  </Col>
                </Row>
                <br />

                <div className="title-card">
                  Nilai Pilar IDSDB - {nameprop}{" "}
                </div>
                <br />
                <Row
                  md={4}
                  className="p-2 g-2 "
                  style={{ justifyContent: "center" }}
                >
                  <Col md={2}>
                    <div className="cards justify-content-center">
                      <div>
                        <CircularProgressbar
                          value={svgmaps[0].lingkungan}
                          text={svgmaps[0].lingkungan}
                          //styles={{ width: "30%" }}
                        />
                        <br />
                        <br />
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "900",
                            color: "#2fb502",
                          }}
                        >
                          LINGKUNGAN LESTARI
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="cards justify-content-center">
                      <div>
                        <CircularProgressbar
                          value={svgmaps[0].ekonomi}
                          text={svgmaps[0].ekonomi}
                          //styles={{ width: "30%" }}
                        />
                        <br />
                        <br />
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "900",
                            color: "#2fb502",
                          }}
                        >
                          EKONOMI BERKELANJUTAN
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="cards justify-content-center">
                      <div>
                        <CircularProgressbar
                          value={svgmaps[0].tatakelola}
                          text={svgmaps[0].tatakelola}
                          //styles={{ width: "30%" }}
                        />
                        <br />
                        <br />
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "900",
                            color: "#2fb502",
                          }}
                        >
                          TATA KELOLA BERKELANJUTAN
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="cards justify-content-center">
                      <div>
                        <CircularProgressbar
                          value={svgmaps[0].sosial}
                          text={svgmaps[0].sosial}
                          //styles={{ width: "30%" }}
                        />
                        <br />
                        <br />
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "900",
                            color: "#2fb502",
                          }}
                        >
                          INKLUSI SOSIAL
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />

                <div className="title-card">
                  Nilai Variabel IDSDB - {nameprop}{" "}
                </div>
                <br />
                {sumdatkab.map((item, index) => (
                  <>
                    <Row>
                      <Col>
                        <div
                          className="cards justify-content-center"
                          style={{ display: "block" }}
                        >
                          <div
                            className="title-card"
                            style={{ color: "#1175BC" }}
                          >
                            LINGKUNGAN LESTARI
                          </div>
                          <Row>
                            <Col className="collvariable">
                              Kualitas Lingkungan Hidup
                            </Col>
                            <Col className="collvariable">
                              {item.lingkunganhidup}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Manajemen SDA Lingkungan
                            </Col>
                            <Col className="collvariable">
                              {item.sdalingkungan}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Resiliensi Lingkungan
                            </Col>
                            <Col className="collvariable">
                              {item.resilisensilingkungan}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="cards justify-content-center"
                          style={{ display: "block" }}
                        >
                          <div
                            className="title-card"
                            style={{ color: "#1175BC" }}
                          >
                            INKLUSI SOSIAL
                          </div>
                          <Row>
                            <Col className="collvariable">Keunggulan SDM</Col>
                            <Col className="collvariable">
                              {item.ekonomisosial}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Kesetaraan Gender
                            </Col>
                            <Col className="collvariable">
                              {item.tenagakerja}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">Keamanan</Col>
                            <Col className="collvariable">
                              {item.konduktivitaskeamanan}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Ketersediaan Infrastruktur Dasar
                            </Col>
                            <Col className="collvariable">
                              {item.infrastrukturdasar}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <div
                          className="cards justify-content-center"
                          style={{ display: "block" }}
                        >
                          <div
                            className="title-card"
                            style={{ color: "#1175BC" }}
                          >
                            EKONOMI BERKELANJUTAN
                          </div>
                          <Row>
                            <Col className="collvariable">
                              Daya Dukung Ekonomi Wilayah
                            </Col>
                            <Col className="collvariable">
                              {item.potensiekonmi}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Kemampuan Fiskal Daerah
                            </Col>
                            <Col className="collvariable">
                              {item.keungandaerah}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Ekosistem Investasi
                            </Col>
                            <Col className="collvariable">
                              {item.ekosisteminvestasi}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">Ketenagakerjaan</Col>
                            <Col className="collvariable">
                              {item.ketenagakerjaan}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Infrastruktur Dasar
                            </Col>
                            <Col className="collvariable">
                              {item.ketersediaaninvrastruktur}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="cards justify-content-center"
                          style={{ display: "block" }}
                        >
                          <div
                            className="title-card"
                            style={{ color: "#1175BC" }}
                          >
                            TATA KELOLA BERKELANJUTAN
                          </div>
                          <Row>
                            <Col className="collvariable">Partisipasi</Col>
                            <Col className="collvariable">
                              {item.kelembagaan}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">Akuntabilitas</Col>
                            <Col className="collvariable">
                              {item.pelayananpublic}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">Transparansi</Col>
                            <Col className="collvariable">{item.kebijakan}</Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">
                              Efisiensi & Inovasi
                            </Col>
                            <Col className="collvariable">
                              {item.efisiensiinovasi}
                            </Col>
                          </Row>
                          <Row>
                            <Col className="collvariable">Keberlanjutan</Col>
                            <Col className="collvariable">
                              {item.keberlanjutan}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            )}
          </Col>

          <br />
          <Row xs={1} md={4} lg={8} className="g-4" id="infomap">
            <Col>
              <div className="cards">
                <div className="block-v" />
                <div className="content-card-h">
                  <text className="title-card">0-30</text>
                  <text className="subtittle-card">Rendah</text>
                </div>
              </div>
            </Col>
            <Col>
              <div className="cards">
                <div className="block-v-r" />
                <div className="content-card-h">
                  <text className="title-card">30.01-60.00</text>
                  <text className="subtittle-card">sedang</text>
                </div>
              </div>
            </Col>
            <Col>
              <div className="cards">
                <div className="block-v-w" />
                <div className="content-card-h">
                  <text className="title-card">60.01-80.00</text>
                  <text className="subtittle-card">Tinggi</text>
                </div>
              </div>
            </Col>
            <Col>
              <div className="cards">
                <div className="block-v-t" />
                <div className="content-card-h">
                  <text className="title-card">80.01-100</text>
                  <text className="subtittle-card">Sangat Tinggi</text>
                </div>
              </div>
            </Col>
          </Row>

          <br />

          <div style={styletblrangking}>
            {/* <input
            type={"text"}
            placeholder="Search Name Kabupaten"
            onKeyUp={(value) => handlekeyup(value.target.value)}
          /> */}
            <Row className="d-flex justify-content-center">
              <Col md={4}>
                <div className="cards justify-content-center">
                  <div>
                    <CircularProgressbar
                      value={avgnas}
                      text={avgnas + "%"}
                      styles={{ width: "30%" }}
                    />
                    <br />
                    <br />
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Nilai Rerata IDSDB {nameprop}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <Table striped bordered responsive>
                  <thead style={{ textAlign: "center" }}>
                    <tr
                      className="row-table-index"
                      style={{
                        backgroundColor: "#0D0845",
                        color: "#ffff",
                        fontWeight: "bolder",
                      }}
                    >
                      <td>
                        {params.get("dat") === "nasional.svg"
                          ? "No"
                          : "RANGKING"}
                      </td>
                      <td>
                        {params.get("dat") === "nasional.svg"
                          ? "PROVINSI"
                          : "KABUPATEN"}
                      </td>
                      <td>LINGKUNGAN LESTARI</td>
                      <td>EKONOMI BERKELANJUTAN</td>
                      <td>INKLUSI SOSIAL</td>
                      <td>TATA KELOLA BERKELANJUTAN</td>
                      <td>IDSDB</td>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {svgmaps.map((item, index) => (
                      <tr
                        onClick={(e) =>
                          clickrangking(item.isprovince, item.code, item.kab)
                        }
                        className="row-table-index"
                        style={{ cursor: "pointer" }}
                      >
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.rangking}</b>
                        </td>
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.kab}</b>
                        </td>
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.lingkungan}</b>
                        </td>
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.ekonomi}</b>
                        </td>
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.sosial}</b>
                        </td>
                        <td style={{ paddingTop: 15 }}>
                          <b>{item.tatakelola}</b>
                        </td>
                        <td
                          style={{
                            paddingTop: 15,
                            backgroundColor: "rgba(196, 248, 218, 0.8)",
                          }}
                        >
                          <b>{item.idsdb}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
