import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DateString from "../Components/DateString";
import "./Pagecontent.css";
import "./News.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";

function Pagecontent() {
  // let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const [datacontent, setDatacontent] = React.useState([]);
  const [paramdata, setparamdata] = React.useState("");
  const paramsurl = new URLSearchParams(window.location.search);
  const getDatacontent = (id) => {
    // alert(params.get("watch"));
    var bodyFormData = new FormData();
    if (id == 0) {
      bodyFormData.append("id", paramsurl.get("watch"));
    } else {
      bodyFormData.append("id", id);
    }

    axios
      .post("/ajaxs/detail-contentjson", bodyFormData)
      .then(function (response) {
        setDatacontent(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const clickmenu = (id, content) => {
  //   document.getElementById("3070").classList.remove("sidebaractive");
  //   document.getElementById("3064").classList.remove("sidebaractive");
  //   document.getElementById("3051").classList.remove("sidebaractive");
  //   document.getElementById("3052").classList.remove("sidebaractive");
  //   document.getElementById("3054").classList.remove("sidebaractive");
  //   document.getElementById("3053").classList.remove("sidebaractive");
  //   if (paramsurl.get("a") == "konsep") {
  //     document.getElementById("3070").classList.remove("sidebaractive");
  //   }

  //   document.getElementById(content).classList.add("sidebaractive");
  //   getDatacontent(content);
  // };
  const clickmenu = (id, content) => {
    var elsa = document.getElementById("3069");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3070");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3071");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3072");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3066");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3064");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3051");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3052");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3053");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3054");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3071");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3072");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3080");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }
    elsa = document.getElementById("3069");
    if (elsa) {
      elsa.classList.remove("sidebaractive");
    }

    document
      .getElementById(paramsurl.get("watch"))
      .classList.remove("sidebaractive");

    document.getElementById(content).classList.add("sidebaractive");
    getDatacontent(content);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      document
        .getElementById(paramsurl.get("watch"))
        .classList.add("sidebaractive");
    }, 400);

    getDatacontent(0);
  }, []);

  return (
    <div className="container-detail" style={{ paddingTop: 120 }}>
      <div className="container">
        <Row>
          <Col
            md={2}
            style={{
              backgroundColor: "#B3D257",
              height: "10%",
              marginBottom: "9px",
            }}
            className="p-0"
          >
            {paramsurl.get("a") === "konsep" ? (
              <>
                <Col
                  id="3090"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("latarbelakang", 3090)}
                >
                  Latar belakang
                </Col>
                <Col
                  id="3069"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("konsep", 3069)}
                >
                  Cakupan Dan Tujuan
                </Col>
                <Col
                  id="3070"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("konsep", 3070)}
                >
                  Konsep IDSDB
                </Col>
                <Col
                  id="3080"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("idsdb", 3080)}
                >
                  Kerangka Pikir
                </Col>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
            {paramsurl.get("a") === "metodelogi" ? (
              <>
                <Col
                  id="3064"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("idsdb", 3064)}
                >
                  IDSDB
                </Col>
                <Col
                  id="3051"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("lingkungan", 3051)}
                >
                  Pilar Lingkungan Lestari
                </Col>
                <Col
                  id="3052"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("ekonomi", 3052)}
                >
                  Pilar Ekonomi Berkelanjutan
                </Col>
                <Col
                  id="3053"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("sosial", 3053)}
                >
                  Pilar Inklusi Sosial
                </Col>
                <Col
                  id="3054"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("kelola", 3054)}
                >
                  Pilar Tata Kelola Berkelanjutan
                </Col>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
            {paramsurl.get("a") === "tentangkami" ? (
              <>
                <Col
                  id="3071"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("lingkungan", 3071)}
                >
                  Konsorsium Pengusung
                </Col>
                <Col
                  id="3072"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("sosial", 3072)}
                >
                  Komite Pengarah
                </Col>
                <Col
                  id="3066"
                  className="sidemenu p-2"
                  onClick={(e) => clickmenu("kelola", 3066)}
                >
                  Kontak kami
                </Col>
              </>
            ) : (
              <>
                <div></div>
              </>
            )}
          </Col>
          <Col
            style={{
              backgroundColor: "#ffff",
              marginLeft: "9px",
              borderRadius: "25px",
            }}
          >
            <br />
            <MuiThemeProvider
              theme={theme}
              style={{ marginBottom: "10px !important" }}
            >
              <Typography
                variant="h3"
                gutterBottom
                align="center"
                style={{ color: "#1175BC" }}
              >
                {datacontent.title}
              </Typography>
            </MuiThemeProvider>

            <Row>
              <div className="body-news">
                <MuiThemeProvider theme={theme}>
                  <Typography variant="body1" align="left" paragraph>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: datacontent.contents,
                      }}
                    />
                  </Typography>
                </MuiThemeProvider>
              </div>
            </Row>
          </Col>
        </Row>
        <br />
      </div>
    </div>
  );
}

export default Pagecontent;
