import React from "react";
import "./Home.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";
// import background from "../Libs/Icon/Mask-group.png";

import CountUp from "react-countup";
import AOS from "aos";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Popup from "../Components/Popup/Popup";

import { useNavigate } from "react-router-dom";
import "aos/dist/aos.css";
import "animate.css/animate.min.css";
import "@fontsource/montserrat";
import { ReactSVG } from "react-svg";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import "leaflet/dist/leaflet.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Path } from "leaflet";

function Home() {
  const lingkunganIcon = require("../Libs/Icon/lingkungan-icon.png");
  const ekonomiIcon = require("../Libs/Icon/ekonomi-icon.png");
  const sosialIcon = require("../Libs/Icon/sosial-icon.png");
  const tatakelolaIcon = require("../Libs/Icon/tatakelola-icon.png");
  const kppod = require("../Libs/Icon/KPPOD.png");
  const terra = require("../Libs/Icon/terra.png");
  const kirana = require("../Libs/Icon/Logokirana.png");

  let theme = createTheme();
  const navigate = useNavigate();
  theme = responsiveFontSizes(theme);
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const [textHome, setTextHome] = React.useState([]);
  const [berkelanjutan, setBerkelanjutan] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [titlecontent, setTitleContent] = React.useState({});
  const [datastatistik, setDatastatistik] = React.useState([]);
  const [avgnas, setavgnas] = React.useState([]);
  const [datanews, setDataNews] = React.useState([]);
  const [baseurlimage, setBaseUrlImage] = React.useState("");
  const [datavideo, setDataVideo] = React.useState([]);
  const [valprovince, setvalprovince] = React.useState("");
  const [dataia, setdataia] = React.useState([]);
  const [dataialabel, setdataialabel] = React.useState("");
  const [dataib, setdataib] = React.useState([]);
  const [dataiblabel, setdataiblabel] = React.useState("");
  const [dataic, setdataic] = React.useState([]);
  const [dataiclabel, setdataiclabel] = React.useState("");
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  ChartJS.defaults.color = "#000";
  const getRanking = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 400);

    axios
      .post("/ajaxs/rangking", bodyFormData)
      .then(function (response) {
        setdataia(response.data.datasatu);
        setdataib(response.data.datadua);
        setdataic(response.data.datatiga);
        setdataialabel(response.data.datasatuname);
        setdataiblabel(response.data.dataduaname);
        setdataiclabel(response.data.datatiganame);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getBerkelanjutan = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 39);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        setBerkelanjutan(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getHomeText = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 50);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        setTextHome(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBerkelanjutanContent = (id) => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", id);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        setTitleContent(response.data.data[0]);
        setPopup(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getStatDaerah = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", "44,53,45,46");
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        setDatastatistik(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getNews = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 49);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        setDataNews(response.data.data);
        setBaseUrlImage(response.data.baseurlimg);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProvince = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("limit", 200);
    axios
      .post("/ajaxs/province", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        setavgnas(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 20000 });
    getRanking();
    getHomeText();
    getBerkelanjutan();
    getStatDaerah();
    getProvince();
    getNews();
  }, []);
  const datacon = {
    labels: [
      "Tata Kelola Berkelanjutan",
      "Ekonomi Berkelanjutan",
      "Inklusi Sosial",
      "Lingkungan Lestari",
    ],
    datasets: [
      {
        label: dataialabel,
        data: dataia,
        borderColor: "rgba(8, 108, 249, 0.8)",
        borderWidth: 3,
        fill: true,
        // backgroundColor: "rgba(156, 192, 241, 0.8)",
      },
      {
        label: dataiblabel,
        data: dataib,
        borderColor: "rgba(174, 126, 8, 0.8)",
        borderWidth: 3,
        fill: true,
        // backgroundColor: "rgba(247, 235, 206, 0.8)",
      },
      {
        label: dataiclabel,
        data: dataic,
        borderColor: "rgba(245, 5, 155, 0.8)",
        borderWidth: 3,
        fill: true,
        // backgroundColor: "rgba(243, 196, 225, 0.8)",
      },
    ],
  };
  const RadarOptions = {
    scales: {
      r: {
        backgroundColor: "rgba(38, 140, 241, 0.03)",
        min: 0,
        max: 100,
        beginAtZero: true,
        angleLines: {
          color: "#3a63a6", // lines radiating from the center
        },
        grid: {
          color: "#B3D257", // lines radiating from the center
        },
        pointLabels: {
          font: {
            size: 11,
            weight: "bolder",
            color: "#000000",
          },

          circular: true,
        },
      },
    },
  };

  return (
    <div className="banner" responsive>
      <Container>
        <Row className="row-b" style={{ marginTop: "90px" }}>
          <Col className="col1-b">
            <div className="banner-content">
              <div
                className="text-banner responsive-font-example"
                dangerouslySetInnerHTML={{ __html: textHome.singkatan }}
              ></div>
              <Button
                style={{ marginTop: "1px" }}
                variant="primary"
                className="button-news-all"
                onClick={() =>
                  window.location.replace("/pagecontent/?watch=3070&a=konsep")
                }
              >
                Selengkapnya
              </Button>
            </div>
          </Col>
          <Col
            md={5}
            className="colimg-b"
            style={{
              backgroundColor: "rgb(179, 208, 235)",
              borderRadius: "25px",
            }}
          >
            <Radar data={datacon} options={RadarOptions} />
          </Col>
        </Row>
      </Container>
      <section>
        <Container className="container-content-2">
          <Row
            className="row-percent-data"
            xs={1}
            md={4}
            lg={8}
            style={{ justifyContent: "center" }}
          >
            {datastatistik.map((item, index) => (
              <Col md={2} key={index} style={{ margin: "10px" }}>
                <div className="col-text-grid">
                  <div className="text-grid-header">
                    <CountUp start={0} end={item.contents} separator="," />
                  </div>
                  <div className="text-grid-body">{item.title}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <AnimationOnScroll animateIn="animate__fadeInDown" delay={20}>
            <Row className="row-b">
              <Col className="col1-b">
                <div className="banner-content">
                  <div
                    className="text-banner"
                    dangerouslySetInnerHTML={{
                      __html: berkelanjutan.singkatan,
                    }}
                  ></div>
                </div>
              </Col>
              <Col md={4} className="colimg-b align-items-start">
                <img
                  src={baseurlimage + berkelanjutan.logoimg}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Button
                  variant="primary"
                  className="button-news-all"
                  onClick={() => getBerkelanjutanContent(39)}
                >
                  Selengkapnya
                </Button>
              </Col>
            </Row>
          </AnimationOnScroll>
        </Container>
      </section>

      <section>
        <Container className="row-grid-menu container" fluid>
          <AnimationOnScroll animateIn="animate__fadeInDown" delay={20}>
            <div>
              <center style={{ color: "#008036", fontSize: "35px" }}>
                <b>Pilar Index Daya Saing Daerah Keberlanjutan </b>
              </center>
            </div>
            <br />
            <Row xs={1} md={4} lg={8} className="g-4">
              <Col>
                <Card
                  className="box-menu"
                  onClick={() =>
                    window.location.replace(
                      "/pagecontent/?watch=3051&a=metodelogi"
                    )
                  }
                >
                  <Card.Body>
                    <div className="box-menu-content">
                      <img className="icon-menu" alt="" src={lingkunganIcon} />
                    </div>
                    <Card.Subtitle className="title-menu">
                      Lingkungan
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="box-menu"
                  onClick={() =>
                    window.location.replace(
                      "/pagecontent/?watch=3052&a=metodelogi"
                    )
                  }
                >
                  <Card.Body>
                    <div className="box-menu-content">
                      <img className="icon-menu" alt="" src={ekonomiIcon} />
                    </div>
                    <Card.Subtitle className="title-menu">
                      Ekonomi
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="box-menu"
                  onClick={() =>
                    window.location.replace(
                      "/pagecontent/?watch=3053&a=metodelogi"
                    )
                  }
                >
                  <Card.Body>
                    <div className="box-menu-content">
                      <img className="icon-menu" alt="" src={sosialIcon} />
                    </div>
                    <Card.Subtitle className="title-menu">Sosial</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className="box-menu"
                  onClick={() =>
                    window.location.replace(
                      "/pagecontent/?watch=3054&a=metodelogi"
                    )
                  }
                >
                  <Card.Body>
                    <div className="box-menu-content">
                      <img className="icon-menu" alt="" src={tatakelolaIcon} />
                    </div>
                    <Card.Subtitle className="title-menu">
                      Tata Kelola
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </AnimationOnScroll>
          <Popup triger={popup} setTrigger={setPopup}>
            <h3>{titlecontent.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: titlecontent.contents }} />
          </Popup>
        </Container>
      </section>

      <section className="section-maping">
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <center>
            <div className="div-col-keberlanjutan" style={{ width: "80%" }}>
              <MuiThemeProvider theme={theme}>
                <Typography variant="h4" className="text-title-berkelanjuntan">
                  Peringkat Indeks Daya Saing Daerah Berkelanjutan <br />{" "}
                  Kabupaten di Indonesia
                </Typography>
              </MuiThemeProvider>
            </div>
          </center>
        </AnimationOnScroll>
        <center>
          <ReactSVG
            afterInjection={(error, svg) => {
              if (error) {
                console.error(error);
                return;
              }
            }}
            beforeInjection={(svg) => {
              svg.classList.add("svg-map");
              // svg.setAttribute("style", "width: 200px");
              const [firstGElement] = [...svg.querySelectorAll("g")];
              // firstGElement
              //   .querySelectorAll("path")
              //   .setAttribute("class", "svg-map__location");

              firstGElement.setAttribute("fill", "white");
              // const [second] = [...firstGElement.querySelectorAll("path")];
              avgnas.map((datamap) => {
                if (datamap.nrt < 30) {
                  const [els] = svg.querySelectorAll(
                    'path[data-a1kpkname="' + datamap.name + '"]'
                  );
                  //console.log(els);
                  els.setAttribute("fill", "#0000ff");
                  els.setAttribute("class", "svg-map__location");
                }
                if (datamap.nrt > 30 && datamap.nrt < 60) {
                  const [els] = svg.querySelectorAll(
                    'path[data-a1kpkname="' + datamap.name + '"]'
                  );
                  //console.log(els);
                  els.setAttribute("fill", "#1175BC");
                  els.setAttribute("class", "svg-map__location");
                }
                if (datamap.nrt > 60 && datamap.nrt < 80) {
                  const [els] = svg.querySelectorAll(
                    'path[data-a1kpkname="' + datamap.name + '"]'
                  );
                  //console.log(els);
                  els.setAttribute("fill", "#B3D257");
                  els.setAttribute("class", "svg-map__location");
                }
                if (datamap.nrt > 80 && datamap.nrt < 100) {
                  const [els] = svg.querySelectorAll(
                    'path[data-a1kpkname="' + datamap.name + '"]'
                  );
                  //console.log(els);
                  els.setAttribute("fill", "#2e9449");
                  els.setAttribute("class", "svg-map__location");
                }
                const [jkt] = svg.querySelectorAll(
                  'path[data-a1kpkname="DKI JAKARTA"]'
                );
                //console.log(els);
                jkt.setAttribute("fill", "#c2bcbc");
                jkt.setAttribute("class", "svg-map__location");
                const [nrt] = svg.querySelectorAll(
                  'path[data-a1kpkname="' + datamap.name + '"]'
                );
                nrt.setAttribute("petasvg", datamap.code);
                nrt.setAttribute("nrt", datamap.nrt);
              });

              // console.log(
              //   firstGElement.querySelectorAll("path")[0]["attributes"][
              //     "data-a1kpkname"
              //   ]["nodeValue"]
              // );
            }}
            loading={() => <span>Loading</span>}
            onClick={(e) => {
              // console.log(e.target);
              if (e.target.getAttribute("petasvg")) {
                window.location.replace(
                  "/index/data/?dat=" +
                    e.target.getAttribute("petasvg") +
                    "&prov=" +
                    e.target.getAttribute("data-a1kpkname")
                );
              }
            }}
            onMouseOver={(e) => {
              // console.log(e.target);
              var tooltipSpan = document.getElementById("tooltip-span");

              //console.log(tooltipSpan);
              var x = e.clientX,
                y = e.clientY;
              tooltipSpan.style.top = y + 15 + "px";
              tooltipSpan.style.left = x + 15 + "px";
              //tooltipSpan.style.display = "block";
              tooltipSpan.style.position = "fixed";
              tooltipSpan.style.overflow = "hidden";
              //console.log(e.target.getAttribute("data-a1kpkname"));
              if (e.target.getAttribute("data-a1kpkname")) {
                tooltipSpan.style.display = "block";
                setvalprovince(
                  e.target.getAttribute("data-a1kpkname") +
                    " - " +
                    e.target.getAttribute("nrt")
                );
              }
            }}
            onMouseOut={(e) => {
              var tooltipSpan = document.getElementById("tooltip-span");
              tooltipSpan.style.display = "none";
              // tooltipSpan.style.;
            }}
            src="https://www.api.idsdb.id/upload/svg/nasional.svg"
          />
          <div
            id="tooltip-span"
            style={{
              display: "none",
              backgroundColor: "black",
              color: "white",
            }}
          >
            {valprovince}
          </div>
          <Button
            variant="primary"
            className="button-news-all"
            size="sm"
            onClick={() => navigate("/index/data/?dat=nasional.svg")}
          >
            Selengkapnya
          </Button>
        </center>
        <Container>
          <div
            className="div-news"
            style={{ justifyContent: "left", color: "#1175BC" }}
          >
            <Row className="row-title-news" xs={1}>
              <Col style={{ marginTop: 5 }}>
                <text
                  className="text-publikasi"
                  style={{ justifyContent: "left", color: "#1175BC" }}
                >
                  News IDSDB{" "}
                </text>
              </Col>
            </Row>
          </div>
          <div className="news-content ">
            <Row>
              {datanews.map((item, index) => (
                <>
                  <div
                    className="col-md-6"
                    style={{
                      padding: 6,
                      cursor: "pointer",
                      borderRadius: "20px",
                    }}
                  >
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="card-body">
                        <Row>
                          <Col md={3} style={{ padding: 0 }}>
                            <img
                              alt=""
                              src={baseurlimage + item.logoimg}
                              className="img-thumbnail"
                              onClick={() =>
                                navigate("/detailnews/" + item.title, {
                                  state: {
                                    contents: item.contents,
                                    image: baseurlimage + item.logoimg,
                                    date: item.ins_date.split(" ")[0],
                                  },
                                })
                              }
                              style={{ borderRadius: "10px" }}
                            />
                          </Col>
                          <Col style={{ padding: 0 }}>
                            <div
                              className="div-news-thumb-row"
                              onClick={() =>
                                navigate("/detailnews/" + item.title, {
                                  state: {
                                    contents: item.contents,
                                    image: baseurlimage + item.logoimg,
                                    date: item.ins_date.split(" ")[0],
                                  },
                                })
                              }
                            >
                              <div className="title-news-thumb">
                                {item.title}
                              </div>
                              <div
                                style={{ marginTop: "3px" }}
                                className="desc-news-thumb"
                                dangerouslySetInnerHTML={{
                                  __html: item.singkatan,
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              ))}
            </Row>
          </div>
          <center>
            <Button
              variant="primary"
              className="button-news-all"
              size="sm"
              onClick={() => navigate("/news/?cat=news")}
            >
              Selengkapnya
            </Button>
          </center>
        </Container>
      </section>
      <center>
        <Row className="divcenter" style={{ backgroundColor: "#E1E1E1" }}>
          <Row style={{ justifyContent: "center" }}>
            <Col
              md={2}
              style={{
                alignSelf: "center",
              }}
            >
              <a href="https://www.kppod.org/">
                <img className="imgfooter" alt="" src={kppod} />
              </a>
            </Col>
            <Col md={2} style={{ alignSelf: "center" }}>
              <img className="imgfooter" alt="" src={terra} />
            </Col>
            <Col md={2} style={{ alignSelf: "center" }}>
              <img className="imgfooter" alt="" src={kirana} />
            </Col>
          </Row>
        </Row>
      </center>
    </div>
  );
}

export default Home;
