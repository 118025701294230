import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DateString from "../Components/DateString";
import "./DetailNews.css";
import "./News.css";
import {
  createTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
function DetailNews() {
  // let navigate = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const axios = require("axios").default;
  axios.defaults.baseURL = "https://api.idsdb.id";
  const [datacontent, setDatacontent] = React.useState([]);
  const [datanews, setDataNews] = React.useState([]);
  const [baseurlimage, setBaseUrlImage] = React.useState("");
  const getDatacontent = () => {
    const params = new URLSearchParams(window.location.search);
    // alert(params.get("watch"));
    var bodyFormData = new FormData();
    bodyFormData.append("id", params.get("watch"));
    axios
      .post("/ajaxs/detail-contentjson", bodyFormData)
      .then(function (response) {
        setDatacontent(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getNews = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("idcategory", 49);
    bodyFormData.append("limit", 8);
    axios
      .post("/ajaxs/dcontentsjsonnew", bodyFormData)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data));
        setDataNews(response.data.data);
        setBaseUrlImage(response.data.baseurlimg);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getDatacontent();
    getNews();
  }, []);
  return (
    <div className="container-detail" style={{ paddingTop: 120 }}>
      <div className="container">
        <MuiThemeProvider theme={theme}>
          <Typography variant="h4" gutterBottom align="center">
            {datacontent.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom align="center">
            {/* {DateString(location.state.date)} */}
          </Typography>
        </MuiThemeProvider>
        <Row xs md={12}>
          <Col className="centerd-img">
            {" "}
            <img
              alt=""
              src={"https://api.idsdb.id/upload/" + datacontent.logoimg}
              className="img-banner"
            />
          </Col>
        </Row>
        <Row>
          <div className="body-news">
            <MuiThemeProvider theme={theme}>
              <Typography variant="body1" align="left" paragraph>
                <div
                  dangerouslySetInnerHTML={{
                    __html: datacontent.contents,
                  }}
                />
              </Typography>
            </MuiThemeProvider>
          </div>
        </Row>
        <Row>
          {datanews.map((item, index) => (
            <>
              <div
                className="col-md-6"
                style={{
                  padding: 6,
                  cursor: "pointer",
                  borderRadius: "20px",
                }}
              >
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <Row>
                      <Col md={3} style={{ padding: 0 }}>
                        <img
                          alt=""
                          src={baseurlimage + item.logoimg}
                          className="img-thumbnail"
                          onClick={() =>
                            navigate("/detailnews/" + item.title, {
                              state: {
                                contents: item.contents,
                                image: baseurlimage + item.logoimg,
                                date: item.ins_date.split(" ")[0],
                              },
                            })
                          }
                          style={{ borderRadius: "10px" }}
                        />
                      </Col>
                      <Col style={{ padding: 0 }}>
                        <div
                          className="div-news-thumb-row"
                          onClick={() =>
                            navigate("/detailnews/" + item.title, {
                              state: {
                                contents: item.contents,
                                image: baseurlimage + item.logoimg,
                                date: item.ins_date.split(" ")[0],
                              },
                            })
                          }
                        >
                          <div className="title-news-thumb">{item.title}</div>
                          <div
                            style={{ marginTop: "3px" }}
                            className="desc-news-thumb"
                            dangerouslySetInnerHTML={{
                              __html: item.singkatan,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <br />
            </>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default DetailNews;
